.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: App-logo-spin infinite 20s linear;
}

.App-header {
  color: white;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.curve-mask {
  width: 100%;
  overflow: hidden;
}

.chakra-checkbox__control.css-xxkadm {
  border-radius: 4px;
  border: 1px solid #187cbe;
}

.css-iykj8u {
  padding: 8px;
  margin-left: 15px;
  background-color: "red" !important;
}

.css-11qe729 {
  min-width: "1170px";
  width: "1170px";
  height: "800px";

}

.chakra-modal__content-container {
  min-width: "1170px";
  height: "800px";
  width: "1170px";
}

@media screen and (max-width: 1300px) {
  .dashboardMap {
    height: 88vh;
    width: 85wh
  }
}
@media screen and (min-width: 1300px) {
  .dashboardMap {
    height: 90vh;
    width: 95wh
  }
}
  #dashboardMap {
   background:"red"
  }