.DateInput {
  overflow: hidden;
}

.SingleDatePickerInput {
  width: 100%;
}

.react-datepicker-ignore-onclickoutside {
  width: 100%;
  outline: none;
}

.react-datepicker-wrapper {
  margin-top: 4px;
}
